@font-face {
    font-family: 'Newake';
    src: url('/assets/fonts/NewakeFont.otf') format('opentype');
  }

  
  @import "src/assets/scss/default/_variables.scss";


  .aboutusmainpage {

    object-fit: cover;
    filter: contrast(1.3) grayscale(0.1) brightness(0.8);
    backdrop-filter: blur(10px) brightness(0.8);
}
.abpos{

}

.red-black-filter {
  /* Siyah ve kırmızı tonlar için filtre */
  filter:  grayscale(100) brightness(0.7);
  /* Ek olarak, bir renk kaplaması eklemek için */
  position: relative;
  transition: filter 0.5s ease;
  padding: 5px;

}


.red-black-filter:hover {

  filter: none;
}
.cort{
    color: var(--color-body);
    opacity: 0.2;
    margin-top: 30px;
}

.gord{
  color: var(--color-body);
  opacity: 0.2;
  margin-top: 30px;
  margin-left: 10px;
}
.diagonal-bar {
  position: relative; /* Pseudo-element için pozisyon referansı */
  width: 300px; /* Görünür bir alan yaratmak için genişlik */
  height: 200px; /* Görünür bir alan yaratmak için yükseklik */
  background: transparent; /* Arka planı ayarlayabilirsiniz */
  overflow: hidden; /* Taşan içerikleri kırp */
}

.diagonal-bar::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%; /* Çubuğun yukarıdan pozisyonunu ayarla */
  left: 0; /* Çubuğun soldan pozisyonunu ayarla */
  width: 0;
  height: 0;
  border-top: 50px solid transparent; /* Üst sınırın boyutunu ayarla */
  border-bottom: 50px solid transparent; /* Alt sınırın boyutunu ayarla */
  border-left: 300px solid red; /* Çubuk genişliği ve rengi */
  transform: translateY(-50%) skewY(-20deg); /* Yatay çubuğu merkezle ve eğ */
  transform-origin: 0 0; /* Dönüşümün başlangıç noktasını ayarla */
}


  .spanaboutus{
    letter-spacing: 3px;

  }

  .c{width:240px;height:240px;border:3px solid #f23030;border-radius:50%;position:absolute;top:50%;left:50%;transform:translate3d(-50%,-50%,-50px);}
.c:after{content:"";position:absolute;left:-10px;top:-10px;width:100%;height:100%;border-radius:50%;border:10px solid #c42323;border-top-color:transparent;border-bottom-color:transparent;animation:R 10s infinite linear;}

.c2{width:100%;height:100%;border:1px solid #c42323;border-radius:50%;position:absolute;top:0;left:0;padding:1px;animation:L 80s infinite linear;box-sizing:border-box;}

.c3{width:33%;height:33%;border:2px solid #a10606;border-radius:50%;transform:translate3d(-50%,-50%,5px);position:absolute;top:50%;left:50%;}
.c3:after{content:"";position:absolute;left:-5px;top:-5px;width:100%;height:100%;border-radius:50%;border:5px solid #a10606;border-top-color:transparent;border-bottom-color:transparent;animation:L 5s infinite linear;}

.c4{width:75px;height:75px;transform:translate3d(-50%,-50%,0px);position:absolute;left:50%;top:50%;}
.c4:after{content:"";width:100%;height:100%;position:absolute;left:-3px;top:-3px;border-radius:50%;border:3px solid #f23030;border-left-color:transparent;animation:R 5s infinite linear;}

.c5{width:77px;height:77px;transform:translate3d(-50%,-50%,65px);position:absolute;left:50%;top:50%;border-radius:50%;}
.c5:after{content:"";width:100%;height:100%;position:absolute;left:-1px;top:-1px;border:1px solid #c42323;border-right-color:transparent;border-bottom-color:transparent;border-radius:50%;animation:L 5s infinite linear;}

.c6{width:55px;height:55px;transform:translate3d(-50%,-50%,61px);position:absolute;left:50%;top:50%;border-radius:50%;}
.c6:after{content:"";width:100%;height:100%;position:absolute;left:-1px;top:-1px;border:1px solid #c42323;border-right-color:transparent;border-top-color:transparent;border-radius:50%;animation:R 10s infinite linear;}

i{height:15px;width:1px;background:#c42323;position:absolute;left:50%;margin-left:-10px;transform-origin:1000% 1005%;}

i:nth-child(1){transform:rotate(-5deg);}
i:nth-child(2){transform:rotate(5deg);}
i:nth-child(3){transform:rotate(15deg);}
i:nth-child(4){transform:rotate(25deg);}
i:nth-child(5){transform:rotate(35deg);}
i:nth-child(6){transform:rotate(45deg);}
i:nth-child(7){transform:rotate(55deg);}
i:nth-child(8){transform:rotate(65deg);}
i:nth-child(9){transform:rotate(75deg);}
i:nth-child(10){transform:rotate(85deg);}
i:nth-child(11){transform:rotate(95deg);}
i:nth-child(12){transform:rotate(105deg);}
i:nth-child(13){transform:rotate(115deg);}
i:nth-child(14){transform:rotate(125deg);}
i:nth-child(15){transform:rotate(135deg);}
i:nth-child(16){transform:rotate(145deg);}
i:nth-child(17){transform:rotate(155deg);}
i:nth-child(18){transform:rotate(165deg);}
i:nth-child(19){transform:rotate(175deg);}
i:nth-child(20){transform:rotate(185deg);}
i:nth-child(21){transform:rotate(195deg);}
i:nth-child(22){transform:rotate(205deg);}
i:nth-child(23){transform:rotate(215deg);}
i:nth-child(24){transform:rotate(225deg);}
i:nth-child(25){transform:rotate(235deg);}
i:nth-child(26){transform:rotate(245deg);}
i:nth-child(27){transform:rotate(255deg);}
i:nth-child(28){transform:rotate(265deg);}
i:nth-child(29){transform:rotate(275deg);}
i:nth-child(30){transform:rotate(285deg);}
i:nth-child(31){transform:rotate(295deg);}
i:nth-child(32){transform:rotate(305deg);}
i:nth-child(33){transform:rotate(315deg);}
i:nth-child(34){transform:rotate(325deg);}
i:nth-child(35){transform:rotate(335deg);}
i:nth-child(36){transform:rotate(345deg);}
i:nth-child(37){transform:rotate(355deg);}
i:nth-child(38){transform:rotate(365deg);}

figure{width:305px;height:305px;margin:0;position:relative;transform:rotateX(-40deg) rotateY(30deg) scale(1.1,1.1);transition:1.4s;transform-style: preserve-3d; opacity: 0.7;
}
figure:hover{transform: rotateX(0deg) rotateY(0deg) scale(1.1,1.1);}

@keyframes R{
  0%{transform:rotate(20deg);}
  100%{transform:rotate(380deg)}
}
@keyframes L{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(-360deg)}
}


.full-width-div {
  display: flex; /* Flexbox kullan */
  align-items: center; /* İçeriği dikey olarak ortala */
  width: 100%; /* Tam genişlik */
  padding: 10px; /* İçerik için biraz boşluk */
  margin-bottom: 10px; /* Div'ler arasında boşluk */
  height: 200px;

}

/* Son div için alt margini sıfırla */
.full-width-div:last-child {
  margin-bottom: 0;
}

.side-image {
  width: 80px; /* Resmin genişliği */
  height: auto; /* Yüksekliği otomatik ayarla */
  margin-right: 20px; /* Sağ tarafta boşluk bırak */
}

.conter {
  flex-grow: 1; /* Kalan alanı doldur */
}
.imgframed {
  box-shadow:
    -30px -30px 0 -30px red,
    30px 30px 0 -30px red;
}


.red-bar {
  position: absolute;
  width: 5px; /* Çubuğun genişliği */
  height: 100%;
  background-color: red; /* Kırmızı renk */
}

.red-bar:first-child {
  left: 0; /* Sol taraftaki çubuk */
}

.red-bar:last-child {
  right: 0; /* Sağ taraftaki çubuk */
}

@keyframes up-down {
  0%, 100% { top: 0; }
  50% { top: 100px; } /* Animasyonun hareket aralığı */
}

.light {
  position: absolute;
  width: 20px; /* Işığın genişliği */
  height: 20px; /* Işığın yüksekliği */
  background-color: red; /* Işık rengi */
  border-radius: 50%; /* Daire şekli */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  animation: up-down 2s infinite; /* Animasyon süresi ve tekrar */
}
.vikkar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 800px;
}


@media (max-width: 576px) {
  .abpos{
    position: absolute;
    height: 400px;
  }
  figure{
    position: absolute;
    margin-top: 330px;
    margin-left: 130px;
    opacity: 0.4;
  }
  .vikkar{
    margin-top: 100px;
    width: 700px;
  }
  .gord{
    font-size: 50px;
  }
}