/*--------------------------
    Navigation Styles 
----------------------------*/
.mainmenu-nav {
    .mainmenu {
        display: flex;
        margin: 0;
        padding: 0;
        > li { 
            > a {
                color: var(--color-body);
                font-size: 17px;
                font-weight: 500;
                padding: 0 17px;
                display: block;
                height: 80px;
                line-height: 80px;
                transition: 0.3s;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
        li {
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            &.has-droupdown {
                .submenu {
                    min-width: 240px;
                    height: auto;
                    position: absolute;
                    top: 90%;
                    left: 0;
                    z-index: 90;
                    opacity: 0;
                    visibility: hidden;
                    text-align: left;
                    padding: 12px 0;
                    transition: 0.3s;
                    border-radius: 0 0 10px 10px;
                    background-color: var(--color-blackest);
                    box-shadow: var(--shadow-lighter);
                    li {
                        a {
                            font-weight: 500;
                            padding: 5px 20px;
                            font-size: 14px;
                            display: block;
                            color: var(--color-body);
                            margin: 0 10px;
                            border-radius: 3px;
                            @extend %transition;
                            &:hover {
                                color: var(--color-primary);
                                background: var(--color-darker);
                            }
                        }
                    }
                }
    
                &:hover {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }

            &.with-megamenu { 
                position: static;

                .rn-megamenu {
                    position: absolute;
                    transition: 0.3s;
                    top: 90%;
                    width: 100%;
                    left: 0;
                    right: 0;
                    padding: 0 15px;
                    visibility: hidden;
                    opacity: 0;
                    .wrapper {
                        border-radius: 0 0 10px 10px;
                        background-color: var(--color-blackest);
                        box-shadow: var(--shadow-lighter);

                        .mega-menu-item {
                            padding: 22px 10px;
                            border-right: 1px solid var(--color-extra04);
                            height: 100%;

                            li {
                                a {
                                    font-weight: 500;
                                    padding: 5px 14px;
                                    font-size: 15px;
                                    display: block;
                                    color: var(--color-body);
                                    margin: 0 10px;
                                    border-radius: 4px;
                                    transition: 0.3s;
                                    display: flex;
                                    align-items: center;

                                    .rn-badge-card {
                                        margin-left: 10px;
                                    }

                                    &:hover {
                                        color: var(--color-primary);
                                        background: var(--color-darker);
                                    }
                                }
                            }
                        }
                    }
                }

                .single-mega-item {
                    &:last-child {
                        .mega-menu-item {
                            border-right: 0 none;
                        }
                    }
                }

                &:hover {
                    .rn-megamenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }

        }
    }
}

.navwreck{
    color: var(--color-body);
    font-size: 19px;
    font-weight: 500;
    padding: 0 17px;
    display: block;
    height: 85px;
    line-height: 80px;
    transition: 0.3s;
    position: relative; /* ::after için konumlandırma yapmak için */

   
}
.navwreck::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    background: transparent;
    position: absolute;
    bottom: -2px; /* Kenar çizgisinin başlangıç pozisyonu */
    left: 0;
    transition: background-color 0.3s;
}


.cobbo{
    .navwreck::after {
        background-color: #134ef0; /* Arka plan rengini mavi yapar */
        color: var(--color-body); /* Renk, CSS değişkeni --color-body'e ayarlanır */
        transition: background-color 0.3s;
    }
}

.divra {
    position: relative;
    height: 60px;
    width: 85%;
    font-size: 20px;
    font-weight:600;
    
    background-color: #323190;
    background: #323190;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #323190, #364abf,#3766d4,#2a68f7);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #323190, #364abf,#3766d4,#2a68f7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity:1;
    color:rgba(255,255,255,0.8);
    border: none;
    cursor: pointer;
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 17px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
        transform: scaleX(-1);
        position: absolute;
        left: 3px;
        top: 3px;

      }
     
  }
  .divra:hover {
    opacity:1;
    background: linear-gradient(to left, #323190 30%, #2a68f7 70%) right;
    background-size: 200%;
    transition: .5s ease-out;
  }
  
.lizo{
    position: relative;
    height: 60px;
    width: 15%;
    font-size: 20px;
    font-weight:600;

    background-color: rgb(0, 0, 0,0.6);
    color:red;
    border: none;
    cursor: pointer;
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:red;
        font-weight:300;
      }
}

  .littedivra {
    position: relative;
    height: 60px;
    width: 15%;
    font-size: 20px;
    font-weight:600;

    background-color: rgb(1, 24, 51,0.6);
    color:rgba(255,255,255,0.8);
    border: none;
    cursor: pointer;
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
      }
     
  }
  .littedivra:hover {
    background-color: rgb(1, 24, 51,1);

    transition: .5s ease-out;
  }
  
  .littedivra svg:hover{
  
  }
  .vikart{

    display: none;
  }
@media(max-width: 991px) {
        
    .navwreck{
        color: var(--color-body);
        font-size: 16px;
        font-weight: 500;
        padding: 0px 0px;
        height: auto;
        margin: 0;
        display: block;
        &:hover {
            color: var(--color-primary);
        }
    }
    .vikart{

        display: block;
      }

  }