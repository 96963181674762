@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  .newakefont{
    font-family: 'Red Hat Display', sans-serif;
  }
  .titwakefont{
    font-family: 'Red Hat Display', sans-serif;
  }
.getbigger{
  text-transform: uppercase;
}
@font-face {
  font-family: 'Hunter';
  src: url('../../fonts/Hunter.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Pearl Golf Script Regular';
  src: url('../../fonts/Pearl Golf Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Joker';
  src: url('../../fonts/joker.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



  @import "src/assets/scss/default/_variables.scss";

  $red: #000;
  $white: #fff;
  

  .btn {
    color: #fff;
    cursor: pointer;
    // display: block;
    font-size:16px;
    font-weight: 400;
    line-height: 45px;
    padding: 0 0 2em;
    max-width: 180px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 
    

    
    @media(min-width: 600px) {
        
      padding: 0 1em 2em;
    

    }
    
    &:hover { text-decoration: none; }
    
  }
  

  .btn-1 {
    background:transparent;
    font-weight: 100;
    
    svg {
      height: 45px;
      left: 0;
      position: absolute;
      top: 0; 
      width: 100%; 
    }
    
    rect {
      fill: none;
      stroke: #fff;
      stroke-width: 2;
      stroke-dasharray: 422, 0;
      transition: all 0.35s linear;
    }
  }
  
  .btn-1:hover {
    background: rgba($red, 0);
    font-weight: 900;
    letter-spacing: 1px;
    
    rect {
      stroke-width: 5;
      stroke-dasharray: 15, 310;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  

  .basee-container {
    position: absolute;
    text-align: left;
    margin-bottom: 6px;
    z-index: 1;
    top: 22%;
    left: 15%;
  }

  .ar {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 18px;
  }
  .rn-header button {
    position: relative;
    height: 50px;
    width: 100%;
    font-size: 18px;
    font-weight:600;

    background-color: #323190;
    background: #323190;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #323190, #364abf,#3766d4,#2a68f7);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #323190, #364abf,#3766d4,#2a68f7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    opacity:0.9;
    color:rgba(255,255,255,0.8);
    border: none;
    cursor: pointer;
  
    /* Gerekirse, metin içeriğini ortalamak için aşağıdaki satırları kullanabilirsiniz */
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px; /* Ikon büyüklüğünü ayarlayabilirsiniz */
        color:rgba(255,255,255,0.8);
        font-weight:300;
        margin-left:7px;
      }
     
  }
  .rn-header button:hover {
    opacity:1;
    background: linear-gradient(to left, #323190 30%, #2a68f7 70%) right;
    background-size: 200%;
    transition: .5s ease-out;
  }
  
  .rn-header button:hover svg {
    font-size: 24px;
    font-weight: 600;
    margin-left: 12px;
  }
  

  .bardor-container {
    display: flex;
    align-items: flex-end;
    height: 100%;
    position: absolute;
    left: 15%;
    bottom: 0;
    z-index: -2;
  }

  .bardor {
    width: 75px;
    height: 85%;
    background-color: #1158fa; /* Renk mavi */
    clip-path: polygon(50% 0%, 100% 10%, 100% 100%, 0% 100%, 0% 10%); /* Üst kısmı üçgen şeklinde kesmek için */
    opacity: 0.4;

  
  }
  .l-shape-container {
    position: absolute;
    width: 100%; /* Genişliği ayarlayabilirsiniz */
    height: 100%; /* Yüksekliği ayarlayabilirsiniz */
    background-color: transparent;
    top: 0;
    z-index: -2;
    overflow: hidden;
  }
  .horizontal-rect {
    position: absolute;
    bottom: 60%;
    width: 70%; /* Yatay çubuğun genişliği */
    height: 75px; /* Yatay çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
    left: calc(15% + 75px);
  }
  .vertical-rect {
    position: absolute;
    top: 0;
    left: 15%;
    width: 75px; /* Dikey çubuğun genişliği */
    height: 40%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  
  .vertical-rect2 {
    position: absolute;
    top: 40%;
    left: 85%;
    width: 75px; /* Dikey çubuğun genişliği */
    height: 100%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */

    opacity: 0.4;
  }

  .horizontal-rectthird {
    position: absolute;
    top: 10%;
    width: 45%; /* Yatay çubuğun genişliği */
    height: 75px; /* Yatay çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
    left: calc(40% + 75px);
  }
  .vertical-rectthird {
    position: absolute;
    top: 0;
    left: 85%;
    width: 75px; /* Dikey çubuğun genişliği */
    height: 10%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  
  .horizontal-rect2third {
    position: absolute;
    top: 10%;
    width: 45%; /* Yatay çubuğun genişliği */
    height: 75px; /* Yatay çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
    left: calc(40% + 75px);
  }
  .vertical-rect2third {
    position: absolute;
    top: calc(10% + 75px);
    left: calc(40% + 75px);
    width: 75px; /* Dikey çubuğun genişliği */
    height: 65%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */

    opacity: 0.4;
  }
  .horizontal-rect3third {
    position: absolute;
    bottom: calc(25% - 150px);
    width: calc(40% + 150px);
    height: 75px; /* Yatay çubuğun yüksekliği */
    background-color: #1158fa; /* Renk maviz */
    opacity: 0.4;
    left: 0;
  }

  .horizantal-rectfour{
    position: absolute;
    top: 7%;
    right: calc(15% - 75px);
    width: 50%; /* Dikey çubuğun genişliği */
    height: 75px; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  
  .vertical-rectfour {
    position: absolute;
    top: calc(7% + 75px);
    right: calc(65% - 150px);
    width: 75px; /* Dikey çubuğun genişliği */
    height: 10%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }

  .vertical-rectfour44 {
    position: absolute;
    top: 0;
    right: calc(15% - 75px);
    width: 75px; /* Dikey çubuğun genişliği */
    height: 7%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  .horizantal-rec2four{
    position: absolute;
    top: calc(17% + 75px);
    right: 0;
    width: calc(65% - 75px); /* Dikey çubuğun genişliği */
    height: 75px; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  .horizantal-rec3four{
    position: absolute;
    top: calc(32% + 75px);
    left: 0;
    width: 50%; /* Dikey çubuğun genişliği */
    height: 75px; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  .vertical-rec33four{
    position: absolute;
    top: calc(32% + 75px);
    left: 50%;
    width: 75px; /* Dikey çubuğun genişliği */
    height: 40%; /* Dikey çubuğun yüksekliği */
    background-color: #1158fa; /* Renk mavi */
    opacity: 0.4;
  }
  .tabstacir {
    width: 60%;
    height: 92%;
    margin: auto;
    display: grid;
    margin-top: 40px; 
    align-items: stretch; /* Stretch items vertically to fill each row */
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .taco{
    display: grid;
    overflow-y: auto;
    height: auto;
  }
.tabstacir li {
position: relative;
    background-color: #212327;
    background-image: linear-gradient(315deg, #000 0%, #1f2324 74%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c4cfde;
    font-family: 'Poppins', sans-serif;
    transition: .5s;
    -webkit-transition: .5s;
    -moz-transition: .5s;
    -ms-transition: .5s;
    -o-transition: .5s;
    cursor: pointer;
    z-index: 2;
}

.tabstacir li:hover {
    color: #3766d4;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    box-shadow: -10px 0px 15px -5px rgba(55, 102, 212, 0.5); /* Blue light shadow */

}
.activetacir:before {
  content: '';
  position: absolute;
  right: 100%; /* Elemanın sol tarafına yerleştir */
  top: 50%; /* Dikey olarak ortala */
  width: 50px; /* Dikdörtgenin genişliği */
  height: 100px; /* Dikdörtgenin yüksekliği */
  background: -webkit-linear-gradient(to right, #323190, #364abf,#3766d4,#2a68f7);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #323190, #364abf,#3766d4,#2a68f7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  transform: translateY(-50%); /* Yükseklik merkezleme */
  opacity: 0.9;
}

.activetacir {
  opacity: 1;
  background-color: black;
    color: #3766d4 !important;
    position: relative;
    z-index: 33;
    border-radius: 0px;
}
.tabstacir li:after {
  opacity: 0.5;
  background-color: black;
    color: #3766d4 !important;
    position: relative;
    z-index: 33;
    border-radius: 0px;
}

.tacircontents {
    width: 80%;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
}

.boxtacir {
  position: relative;
  z-index: 12;
    gap: 20px;
    background-color: #022140;
    background-color: #2d3436;
    background-image: linear-gradient(315deg, #022140 0%, #000000 74%);
     border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 20px;
    width: 100%;
    animation: moving 1s ease;
    -webkit-animation: moving 1s ease;
}

.boxtacir img {
    width: 50%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.boxtacir h3 {
    color: #c4cfde;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
}

.boxtacir p {
    color: #c4cfde;
    opacity: .5;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
}

.show {
    display: flex;
}

.hide {
    display: none;
}

@keyframes moving {
    from {
        transform: translateX(-50px);
        -webkit-transform: translateX(-50px);
        -moz-transform: translateX(-50px);
        -ms-transform: translateX(-50px);
        -o-transform: translateX(-50px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        opacity: 1;
    }
}
  /* Bu stil, TEKLİF AL butonunu mobil görünümde düzenlemek içindir */

  @media (max-width: 992px) {
    .rn-header button {
      display:none;
    }
  }

  .bar{
    font-weight: 800;
    font-size: 50px;    
    margin: 0;
    padding: 0;
    color: white;
    max-width: 1000px;
    word-wrap: break-word;
  }

  .btn-cont {
    color: var(--color-body);
    font-size: 19px;
    font-weight: 500;
    display: block;
    height: 95px;
    line-height: 80px;
    transition: 0.3s;

    .btnn {
        position: relative;
        padding: 6px 17px;
    -webkit-font-smoothing: antialiased;
      
      &:hover {
        border: none;
        color: var(--color-body);
   
    
      }
    }
    
    .line-1 {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: white;
      left: 0;
      bottom: 0;
    }
    .line-2 {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: white;
      left: 0;
      top: 0;
    }
    .line-3 {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      background-color: white;
      right: 0;
      top: 0;
    }
    .line-4 {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: white;
      right: 0;
      bottom: 0;
    }
  }
  
  @keyframes move1 {
    0% {
      height: 100%; 
      bottom: 0;
    }
    54% {
      height: 0; 
      bottom: 100%;
    }
    55% {
      height: 0; 
      bottom: 0;
    }
    100% {
      height: 100%; 
      bottom: 0;
    }
  }
  
  @keyframes move2 {
    0% {
      width: 0;
      left: 0;
    }
    50% {
      width: 100%; 
      left: 0;
    }
    100% {
      width: 0; 
      left: 100%;
    }
  }
  
  @keyframes move3 {
    0% {
      height: 100%; 
      top: 0;
    }
    54% {
      height: 0; 
      top: 100%;
    }
    55% {
      height: 0; 
      top: 0;
    }
    100% {
      height: 100%; 
      top: 0;
    }
  }
  
  @keyframes move4 {
    0% {
      width: 0; 
      right: 0;
    }
    55% {
      width: 100%; 
      right: 0;
    }
    100% {
      width: 0; 
      right: 100%;
    }
  }